<template>
    <dynamic-page-renderer :page="definition" />
</template>

<script>
import DynamicPageRenderer from "@Platon/components/pages/DynamicPageRenderer"

export default {
    name: "TestDynamicComponentsPage",

    components: { DynamicPageRenderer },

    data() {
        return {
            /**
             * @type DynamicComponent
             */
            definition: {
                name: "RootPage",
                content: `
					<platon-a>Test component</platon-a>
				`,
                css: `* {color: red}`,
                js: `{ mounted() { console.log('Root mounted') } }`,

                components: [
                    {
                        name: "platon-a",
                        content: "<b class='platon-a' @click='event'>PLATON A <platon-b></platon-b></b>",
                        css: ".platon-a {font-size: 40px;}",
                        js: `{
    mounted()
    {
        console.log('Here', this)
    },

    methods: {
        event() {
            alert(1)
            this.$emit('a', '1')
        }
    }
}`
                    },
                    {
                        name: "platon-b",
                        content: "<b class='platon-b'>PLATON B</b>",
                        css: ".platon-b {font-size: 50px}",
                        js: "a"
                    }
                ]
            }
        }
    }
}
</script>
